<template>
  <div id="homeiz">
    <div class="wrapper">
      <router-view />
    </div>
  </div>
</template>
<script>

import { socialvue } from '../config/pluginInit'
export default {
  name: 'BlankLayout',
  components: { },
  mounted () {
    socialvue.mainIndex()
  }
}
</script>
<style>
@import url("../assets/css/custom.css");

.modal-backdrop {
  opacity: .9;
}
</style>
